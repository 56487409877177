<template>
  <div>
     <a class="btn" href="weixin://dl/business/?t=6MRRQRLnw0r">打开小程序</a>
  </div>
</template>
<script>
    
export default {
  data(){
    return {
    }
  },
  
  mounted(){
      //this.init()
  },
  methods: {
    init(){
     // window.location.href='weixin://dl/business/?t=Q3EZVF6aQ6b'
      
    }
  }
};
</script>
<style scoped>
.btn{
  width: 50%;
  height: 40px;
  line-height: 40px;
  margin-top: 25%;
  display: inline-block;
  background: #07c160;
  border: 1px solid #07c160;
  border-radius: 5px;
  color: #ffffff;
  list-style: none;
    text-decoration: none;
}

</style>